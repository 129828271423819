import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@logic-suite/shared/auth';
import { CustomerGuard, HasAppGuard, HasFeatureGuard } from './shared/access';
import { AppListComponent } from './views/app-list/app-list.component';
import { HelpComponent } from './views/help/help.component';
import { PaletteComponent } from './views/palette/palette.component';

const routes: Routes = [
  { path: '', component: AppListComponent, canActivate: [AuthGuard, CustomerGuard] },
  { path: 'palette', component: PaletteComponent, title: 'Palette | Noova Energy' },
  {
    path: 'help',
    component: HelpComponent,
    canLoad: [AuthGuard],
    canActivate: [CustomerGuard, HasAppGuard],
    title: 'Help | Noova Energy',
  },
  {
    path: 'select-customer',
    loadChildren: () => import('./views/select-customer/select-customer.module').then((m) => m.CustomerModule),
    canLoad: [AuthGuard],
    data: { animations: 'backslide' },
    title: 'Select Customer | Noova Energy',
  },

  // Feature views
  {
    path: 'users',
    canActivate: [AuthGuard, CustomerGuard, HasFeatureGuard],
    data: { feature: 'user-management' },
    loadChildren: () => import('./views/user-management/user-management.module').then((m) => m.UserManagementModule),
    title: 'User Management | Noova Energy',
  },
  {
    path: 'config',
    canActivate: [AuthGuard, CustomerGuard, HasFeatureGuard],
    data: { feature: 'configuration' },
    loadChildren: () => import('./views/configuration/configuration.module').then((m) => m.ConfigurationModule),
    title: 'Configuration | Noova Energy',
  },

  // Micro applications
  {
    path: 'insights',
    canActivate: [AuthGuard, CustomerGuard, HasAppGuard],
    loadChildren: () => import('./views/insights/insights.module').then((m) => m.InsightsModule),
    data: { feature: 'insights' },
    title: 'Insights | Noova Energy',
  },
  {
    path: 'invoice',
    canActivate: [AuthGuard, CustomerGuard, HasAppGuard],
    loadChildren: () => import('./views/invoice/invoice.module').then((m) => m.InvoiceModule),
    title: 'Invoice | Noova Energy',
  },
  {
    path: 'advise',
    canActivate: [AuthGuard, CustomerGuard, HasAppGuard],
    loadChildren: () => import('./views/advise/dashboard.module').then((m) => m.DashboardModule),
    title: 'Notifications | Noova Energy',
  },
  {
    path: 'energy',
    canActivate: [AuthGuard, CustomerGuard, HasAppGuard],
    loadChildren: () => import('./views/energy/dashboard.module').then((m) => m.DashboardModule),
    title: 'Energy | Noova Energy',
  },
  {
    path: 'sense',
    canActivate: [AuthGuard, CustomerGuard, HasAppGuard],
    loadChildren: () => import('./views/sense/dashboard.module').then((m) => m.DashboardModule),
    title: 'Sensors | Noova Energy',
  },
  {
    path: 'facility',
    canActivate: [AuthGuard, CustomerGuard, HasAppGuard],
    loadChildren: () => import('./views/facility/dashboard.module').then((m) => m.DashboardModule),
    title: 'Facility | Noova Energy',
  },
  {
    path: 'profile',
    canActivate: [AuthGuard, CustomerGuard, HasAppGuard],
    loadChildren: async () => (await import('@suite/profile-feature')).ProfileModule,
  },

  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./views/public/dashboard/dashboard.module').then(m => m.DashboardModule),
  // },

  // Fallback route
  { path: '**', redirectTo: '' },
];

export const AppRoutes = RouterModule.forRoot(routes, {
  // relativeLinkResolution: 'legacy',
  // onSameUrlNavigation: 'reload',
  // initialNavigation: 'enabledBlocking',
});
